export default {
  components: {
    verifyTinDialog: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session-signatures/VerifyTinDialog"
      );
    },
    signatureDialog: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session-signatures/SignatureDialog"
      );
    },
  },
  data: () => ({
    items: [],
    headers: [
      { value: "tin", text: "NIF", sortable: false },
      { value: "name", text: "Nombre", sortable: false },
      { value: "signatureAmount", text: "Firmas", sortable: false },
      { value: "actions", text: "", sortable: false },
    ],
    loading: true,
    signatureDialogVisible: null,
    signatureDialogData: null,
    signatureDialogKey: 0,
    trainerTin: null,
    semicyuc: null,
  }),
  async mounted() {
    await this.initialGetSignatureData();
  },
  methods: {
    async initialGetSignatureData() {
      try {
        this.loading = true;
        await this.$store.state.currentService.generateDocuments(
          this.$route.params.id,
          this.signatureMoments
        );
        await this.getSessionSignaturesData();
      } catch (e) {
        this.$notifyError(undefined, "Error al generar los documentos de firma | " + e);
      } finally {
        this.loading = false;
      }
    },
    async getSessionSignaturesData() {
      try {
        this.loading = true;
        const response = await this.$store.state.currentService.getSessionSignaturesData(
          this.$route.params.id
        );
        this.items = response.data.pendingSignatures;
        this.trainerTin = this.items.find(i => i.isTrainer).tin.toLowerCase();
        this.semicyuc = response.data.isSemicyuc;
      } catch (e) {
        this.$notifyError(undefined, "Error al obtener los datos de los firmantes | " + e);
      } finally {
        this.loading = false;
      }
    },
    startSignatureProcess(studentId) {
      if (studentId) {
        this.signatureDialogData = this.items.find(s => s.studentInfo.id === studentId);
      } else {
        this.signatureDialogData = this.items.find(s => s.isTrainer);
      }
      this.refreshSignatureDialog();
      this.signatureDialogVisible = true;
    },
    async closeSignatureDialog() {
      this.signatureDialogData = null;
      await this.getSessionSignaturesData();
    },
    refreshSignatureDialog() {
      this.signatureDialogKey++;
    },
    hideTin(tin) {
      const visibleDigits = Math.ceil(tin.length * 0.3);
      const amountAsterisk = tin.length - visibleDigits;
      let asterisks = "";
      for (let i = 0; i < amountAsterisk; i++) {
        asterisks += "*";
      }
      return asterisks + tin.slice(visibleDigits * -1);
    },
    getStudentName(studentInfo = null) {
      if (!studentInfo) return "Formador";
      return `${studentInfo.name} ${studentInfo.surname}`;
    },
  },
};
